<template>
  <a-modal title="上传详情" v-model="visible" @cancel="$emit('cancel')">
    <!-- slot -->
    <template slot="footer">
      <a-button key="submit" type="primary" @click="$emit('cancel')"> 关闭 </a-button>
    </template>

    <!-- content -->
    <a-descriptions :column="1">
      <a-descriptions-item label="上传类型">{{ detail.uploadType }}</a-descriptions-item>
      <a-descriptions-item label="文件名">{{ detail.fileName }}</a-descriptions-item>
      <a-descriptions-item label="上传时间">{{ detail.uploadTime }}</a-descriptions-item>
      <a-descriptions-item label="上传结果">
        <span :style="{ color: detail.uploadResult.statusColor }">{{ detail.uploadResult.status }}</span>
        <!-- 上传中 -->
        <span v-if="detail.uploadResult.statusType === uploadStatusMap.get('上传中')"
          >(上传结果将以
          <span style="color: black">{{ detail.uploadResult.messageType }}</span>
          同步)</span
        >
        <!-- 部分成功 -->
        <span
          v-if="
            [uploadStatusMap.get('部分成功'), uploadStatusMap.get('上传失败')].includes(detail.uploadResult.statusType)
          "
        >
          <a-button type="link" @click="downloadErrorFiles" :loading="downloading">导出上传失败文件</a-button>
        </span>
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>
<script>
import { getImportErrorFiles } from '@/api/headTeacher';
import { uploadStatusColor, uploadStatusMap, uploadTypeMap } from './constants';
import downloadBlob from '@/utils/download-blob';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Function,
      default: () => {},
    },
    params: {
      type: Object,
    },
    uuid: {
      type: String,
    },
  },
  data() {
    return {
      uploadStatusMap,
      downloading: false,
    };
  },
  computed: {
    // model
    detail() {
      const { fileName, type, status, whenCreated } = this.params ?? {};
      return {
        uploadType: uploadTypeMap.get(type),
        fileName,
        uploadTime: whenCreated,
        uploadResult: {
          status: uploadStatusMap.get(status),
          statusColor: uploadStatusColor[status],
          statusType: status,
          messageType: '飞书通知',
        },
      };
    },
  },
  methods: {
    async downloadErrorFiles() {
      try {
        this.downloading = true;
        const res = await getImportErrorFiles(this.params?.uuid);
        const originFileName = this.detail.fileName.replace('.xlsx', '');
        const now = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        const exportFileName = `${originFileName}（错误列表文件）-${now}.xlsx`;
        downloadBlob(exportFileName, res.data);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>
<style scoped lang="less"></style>
