<template>
  <div class="dataUpload-wrap">
    <div class="task-nav">
      <div style="flex: 1">
        <a-row :gutter="24">
          <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" class="ant-advanced-search-form">
            <a-col :span="8">
              <a-form-model-item label="上传类型：">
                <a-select placeholder="请选择" @change="getUploadData('search')" v-model="uploadTypeName" allow-clear>
                  <a-select-option :value="item.value" v-for="(item, index) in uploadTypeOptions" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-form-model>
        </a-row>
      </div>
      <div class="button-box">
        <a-button type="primary" style="margin-right: 10px" @click="getUploadData('search')">查询</a-button>
        <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
      </div>
    </div>
    <div class="table-wrap">
      <div class="sel-navwrap">
        <span class="operator-btn" @click="uploadData"> 上传数据 </span>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="initPagination"
        @change="handleTableChange"
        :loading="loading"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="type" slot-scope="type, record">
          <span> {{ initOptionsType(type, uploadTypeOptions) }}</span>
        </template>
        <template slot="result" slot-scope="result, record">
          <span :style="{ color: uploadStatusColor[result] }">{{ uploadStatusMap.get(result) }}</span>
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-button v-if="record.method === 'ASYNCH'" type="link" @click="showUploadResultModal(record)">详情</a-button>
        </template>
      </a-table>
    </div>
    <a-modal
      title="上传数据"
      :visible="visible"
      width="430px"
      :footer="!showBtn ? null : undefined"
      dialogClass="schedule-related"
      @cancel="onCancel(0)"
    >
      <a-form v-if="showForm" :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="上传类型" :wrapper-col="{ span: 18 }">
          <a-select
            v-decorator="['type', { rules: [{ required: true, message: '请选择上传类型!' }] }]"
            placeholder="请选择"
            allow-clear
            @change="changeType"
          >
            <a-select-option :value="item.value" v-for="(item, index) in filteredUploadTypeOptions" :key="index">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择月份" :wrapper-col="{ span: 18 }" v-if="uploadType === 'CLASSADMIN_MONTH_LIMIT'">
          <a-month-picker
            :disabled-date="disabledDate"
            style="width: 100%"
            v-decorator="['month', { rules: [{ required: true, message: '请选择选择月份!' }] }]"
            format="YYYY-MM"
          />
        </a-form-item>
        <!--   accept="application/vnd.ms-excel"-->
        <a-form-item label="上传文件">
          <a-upload
            @change="handleChange"
            :before-upload="beforeUpload"
            :remove="handleRemove"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :file-list="fileList"
            v-decorator="['file', { getValueFromEvent: normFile, rules: [{ required: true, message: '请上传文件!' }] }]"
          >
            <a-button> <a-icon type="upload" /> 选择文件 </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
      <div class="progress-wrap" v-if="!showForm && !errorFlag">
        <div class="progress">
          <a-progress type="circle" :percent="percent" />
        </div>
        <div class="progress-txt" v-if="!uploadFlag">《{{ fileName }}》上传中，请稍后</div>
        <div class="progress-txt1" v-if="!uploadFlag">注意：请勿关闭当前页面</div>
        <div class="progress-txt" v-if="uploadFlag">数据上传成功</div>
      </div>
      <div class="error-wrap" v-if="errorFlag">
        <div class="error-pic">
          <img src="@/assets/newImage/error-icon.png" />
        </div>
        <div class="progress-txt">数据上传失败</div>
        <div class="progress-txt1">点击下载失败文件，修改后可重新上传</div>
      </div>
      <div slot="footer" v-if="showBtn">
        <a-button @click="onCancel(0)" v-if="showForm">取消</a-button>
        <a-button type="primary" @click="handleSubmit" v-if="showForm" :loading="uploadSubmitLoading">提交</a-button>
        <a-button @click="onCancel(1)" v-if="errorFlag">忽略并关闭</a-button>
        <a-button type="primary" @click="downloadFile" v-if="errorFlag">下载失败文件</a-button>
      </div>
    </a-modal>
    <UploadResultModal :params="currentRow" :visible="uploadResultVisible" @cancel="closeUploadResultModal" />
  </div>
</template>

<script>
import { uploadDataForm, queryUploadData, uploadData } from '@/api/headTeacher';
import downloadBlob from '@/utils/download-blob';
import { uploadTypeOptions, getUploadMode } from './dataUploads/constants';
import { makeAsyncValidator } from '@/utils';
import UploadResultModal from './dataUploads/UploadResultModal.vue';
import { uploadStatusMap, uploadStatusColor } from './dataUploads/constants';
import { getFileSizeMB } from '@/utils/file';

// MB
const maxUploadFileSize = 10;
export default {
  name: 'DataUpload',
  components: { UploadResultModal },
  data() {
    return {
      uploadStatusMap,
      uploadStatusColor,
      uploadResultVisible: false,
      uploadType: '',
      uploadTypeOptions,
      uploadSubmitLoading: false,
      fileName: '',
      loading: false,
      currentRow: null,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '文件名',
          dataIndex: 'fileName',
          key: 'fileName',
          ellipsis: true,
          width: 220,
        },
        {
          title: '上传类型',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: { customRender: 'type' },
        },

        {
          title: '上传结果',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'result' },
        },
        {
          title: '上传人',
          dataIndex: 'operatorName',
          key: 'operatorName',
        },
        {
          title: '数据上传时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
          width: 220,
        },
        {
          title: '数据更新时间',
          dataIndex: 'whenModified',
          key: 'whenModified',
          width: 220,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 70,
          scopedSlots: { customRender: 'operation' },
        },
      ],

      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      pageNum: 1,
      pageSize: 10,
      current: 0,
      tableData: [],
      errorFile: null,
      showBtn: true,
      errorFlag: false,
      uploadFlag: false,
      showForm: true,
      percent: 0,
      uploadTypeName: null,
      visible: false,
      form: this.$form.createForm(this),
      fileList: [],
      timer: null,
    };
  },
  computed: {
    filteredUploadTypeOptions() {
      return this.uploadTypeOptions.filter((item) => !['STUDENT_RANK', 'CLASSADMIN_PERFORMANCE'].includes(item.value));
    },
  },
  methods: {
    showUploadResultModal(record) {
      this.currentRow = record;
      this.uploadResultVisible = true;
    },
    closeUploadResultModal() {
      this.uploadResultVisible = false;
    },
    changeType(val) {
      this.uploadType = val;
    },
    disabledDate(current) {
      return current && current < this.$moment().subtract(1, 'month');
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    getUploadData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        type: this.uploadTypeName,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.loading = true;
      queryUploadData(params).then((res) => {
        this.loading = false;
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.current = res.data.currentPage;
      });
    },
    handleTableChange(data) {
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.pageNum = data.current;
      this.getUploadData();
    },
    downloadFile() {
      downloadBlob(this.fileName, this.errorFile);
    },
    addProgress() {
      this.timer = setInterval(this.increase, 500);
    },
    increase() {
      let percent = this.percent + 10;
      if (percent > 90) {
        percent = 90;
      }
      this.percent = percent;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      // this.form.setFieldsValue({file: this.fileList});
    },
    normFile(e) {
      if (Array.isArray(e)) {
        return e;
      }
      e.fileList = this.fileList;
      return e && e.fileList;
    },
    resetCondition() {
      this.uploadTypeName = null;
      this.getUploadData('search');
    },
    uploadData() {
      this.visible = true;
    },

    onCancel(val) {
      this.visible = false;
      this.form.resetFields();
      this.fileList = [];
      this.showForm = true;
      this.percent = 0;
      this.fileName = '';
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.uploadFlag = false;
      this.errorFlag = false;

      this.showBtn = true;
    },
    // 只上传一个文件
    handleChange(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      //  this.form.setFieldsValue({file: this.fileList});
    },
    // 防止选择文件就立马上传
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    validateFields() {
      return makeAsyncValidator(this.form.validateFields)();
    },
    resetUploadForm() {
      this.visible = false;
      this.form.resetFields();
      this.fileList = [];
    },
    async handleSubmit(e) {
      e.preventDefault();

      const { values } = await this.validateFields();
      const { type } = values;

      if (getFileSizeMB(this.fileList[0].size) >= maxUploadFileSize) {
        this.$message.error(`文件大小不能超过${maxUploadFileSize}MB，请拆成多个上传`);
        return;
      }
      // 组装数据？
      this.fileName = this.fileList[0].name;
      const params = new FormData(); // 创建FormData对象,
      params.append('file', this.fileList[0].originFileObj);
      params.append('type', values.type);
      if (this.uploadType === 'CLASSADMIN_MONTH_LIMIT') {
        params.append('month', this.$moment(values.month).format('YYYY-MM'));
      }

      switch (getUploadMode(type)) {
        // 异步上传
        case 'async':
          this.uploadSubmitLoading = true;
          uploadData(params)
            .then(({ data }) => {
              // 失败
              if (data.status === 'FAIL') {
                const h = this.$createElement;

                this.$info({
                  title: '上传失败',
                  content: h('div', {}, [
                    data.failReason?.split(';').map((text) => h('div', null, text)),
                    (data.failRowNum??0)>0 ? h('div', null, `错误行数：${data.failRowNum}`) : null,
                  ]),
                  // content: `${data.failReason} ${(data.failRowNum ?? 0) > 0 ? `错误行数：${data.failRowNum}` : ''}`,
                });
                // this.$message.error(data.failReason, 0);
                this.uploadSubmitLoading = false;
                return;
              }
              this.$message.success('提交成功');
              this.visible = false;
              this.uploadSubmitLoading = false;
              this.resetUploadForm();
              this.getUploadData('search');
            })
            .catch((error) => {
              this.uploadSubmitLoading = false;
              this.$message.error('上传失败');
            });
          break;
        // 同步上传
        case 'sync':
          // 展示进度条
          this.showForm = false;
          this.showBtn = false;
          this.addProgress();
          uploadDataForm(params).then((res) => {
            if (this.timer) {
              clearInterval(this.timer);
            }
            // 上传成功的
            if (res.data.size > 0) {
              // 上传失败，弹出失败弹窗，可以下载
              this.showBtn = true;
              this.errorFlag = true;
              this.errorFile = res.data;
              this.resetUploadForm();
              this.getUploadData('search');
            } else {
              this.percent = 100;
              this.uploadFlag = true;
              this.visible = false;
            }
          });
      }
    },
  },
  mounted() {
    this.getUploadData();
  },
};
</script>

<style lang="less" scoped>
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.button-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.sel-navwrap {
  text-align: right;
  margin-bottom: 20px;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid #00bf8a;
    color: #00bf8a;
  }
}
.progress {
  text-align: center;
}
.progress-txt {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  margin-top: 12px;
}
.progress-txt1 {
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  text-align: center;
  margin-top: 8px;
}
.error-pic {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.errorTxt {
  color: red;
}
</style>
