import { find, map } from 'ramda';

/**
 * 上传类型映射
 */
const uploadStatusMap = new Map([
  ['UPLOADING', '上传中'],
  ['PARTIAL_SUCCESS', '部分成功'],
  ['SUCCESS', '上传成功'],
  ['FAIL', '上传失败'],
  ['上传中', 'UPLOADING'],
  ['部分成功', 'PARTIAL_SUCCESS'],
  ['上传成功', 'SUCCESS'],
  ['上传失败', 'FAIL'],
]);

/**
 * 上传类型映射
 */
const uploadTypeMap = new Map([
  ['STUDENT_RANK_V2', '学员分层2.0'],
  ['CLASSADMIN_MONTH_LIMIT', '班主任每月限额'],
  ['OFFLINE_TASK', '精细化任务'],
  ['STUDENT_RANK', '学员分层'],
  ['CLASSADMIN_PERFORMANCE', '班主任业务数据'],

]);

/**
 * 上传类型
 * 原枚举字段名作保留
 * STUDENT_RANK:'学员分层',
 * CLASSADMIN_PERFORMANCE:'班主任业务数据'
 */
const uploadTypes = [
  {
    key: 'STUDENT_RANK_V2',
    name: '学员分层2.0',
    uploadMode: 'async',
  },
  {
    key: 'CLASSADMIN_MONTH_LIMIT',
    name: '班主任每月限额',
    uploadMode: 'sync',
  },
  {
    key: 'OFFLINE_TASK',
    name: '精细化任务',
    uploadMode: 'async',
  },
  {
    key: 'STUDENT_RANK',
    name: '学员分层',
    uploadMode: 'sync',
  },
  {
    key: 'CLASSADMIN_PERFORMANCE',
    name: '班主任业务数据',
    uploadMode: 'sync',
  },
];

/**
 * 上传下拉 Options
 */
const uploadTypeOptions = map(({ key, name }) => ({
  label: name,
  value: key,
}))(uploadTypes);

const getUploadMode = (key) =>
  find((option) => {
    return option.key === key;
  }, uploadTypes).uploadMode;

/**
 * 上传状态颜色
 */
const uploadStatusColor = {
  UPLOADING: 'black',
  PARTIAL_SUCCESS: 'rgb(255, 112, 112)',
  done: 'black',
  FAIL: 'rgb(255, 112, 112)',
};

export { uploadTypeOptions, getUploadMode, uploadStatusMap, uploadTypeMap, uploadStatusColor };
