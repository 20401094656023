/**
 * @param {Function} validator antd form 表单校验器
 */
const makeAsyncValidator = (validator) => {
  return () =>
    new Promise((res, rej) => {
      validator((error, values) => {
        if (error) {
          rej({ error });
        }
        res({ values });
      });
    });
};

export { makeAsyncValidator };
